import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[_c(VBtn,{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[(_vm.value)?_c(VIcon,[_vm._v(" mdi-view-quilt ")]):_c(VIcon,[_vm._v(" mdi-dots-vertical ")])],1),_c(VToolbarTitle,{staticClass:"hidden-sm-and-down font-weight-light",domProps:{"textContent":_vm._s(_vm.$route.name)}}),_c(VSpacer),_c('div',{staticClass:"body-1 font-weight-medium"},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.computedValue))]),_vm._v(" | "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"mx-3"}),_c(VBtn,{staticClass:"ml-2",attrs:{"min-width":"0","text":"","to":"/"}},[_c(VIcon,[_vm._v("mdi-view-dashboard")])],1),_c(VMenu,{attrs:{"close-on-content-click":true,"nudge-width":200,"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2",attrs:{"dark":""}},on),[_c(VIcon,[_vm._v("mdi-account")])],1)]}}])},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemAvatar,[_c('img',{attrs:{"src":_vm.photo}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.email)+" ")])],1)],1)],1),_c(VDivider),_c(VCardActions,{attrs:{"right":""}},[_c(VBtn,{attrs:{"text":"","color":"normal"},on:{"click":_vm.logOut}},[_c('i',[_vm._v("Logout")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }